/**
 * This script should be put in the head of index
 * to force redirection if the page is not accessed in iframe
 */

import { KAIOS_HOMEPAGE } from '../constant';

if (window.self === window.top) {
  window.location.replace(KAIOS_HOMEPAGE);
}
